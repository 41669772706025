import { useState } from 'react';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import OktaUserApi from '../api/OktaUserApi';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const UserSelfHelp = () => {
  const [activateUserId, setActivateUserId] = useState<string>();
  const [showSelfHelpModal, setShowSelfHelpModal] = useState(false);
  const handleSelfHelpModalHide = () => setShowSelfHelpModal(false);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [doneProcessing, setDoneProcessing] = useState(false);

  const handleSelfHelpModalShow = () => {
    setIsFormValidated(false);
    setDoneProcessing(false);
    setShowSelfHelpModal(true);
  };

  const handleUserActivate = (event: { currentTarget: any }) => {
    const form = event.currentTarget.form;
    setDoneProcessing(false);

    if (form?.checkValidity()) {
      setIsProcessing(true);
      if (activateUserId) {
        OktaUserApi.activateUser(activateUserId)
          .then(() => {
            // set message visible
            setIsFormValidated(false);
            setIsProcessing(false);
            setDoneProcessing(true);
          })
          .catch((err) => {
            console.error('failed', err);
          });
      }
    }

    setIsFormValidated(true);
  };

  return (
    <>
      <Button className='ms-3' onClick={() => handleSelfHelpModalShow()} variant='secondary'>
        Get Sign-in Help
      </Button>
      <Modal size='lg' show={showSelfHelpModal} onHide={handleSelfHelpModalHide}>
        <Modal.Header closeButton>
          <Modal.Title>Sign-in/up Help</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-3'>
            Review the options below for self-help related to signing in or signing up.
          </div>
          <div>
            <ul>
              <li>
                If you have forgotten your password, go to Sign-in to reset your password. On the
                sign-in page enter your username/email and then click the Next button. Then, click
                on the "Forgot password?" link to send yourself a reset password email.
              </li>
              <li>
                If you have entered the wrong password too many times and locked your account, go to
                Sign-in and click on the "Unlock account?" link to send yourself an email to unlock
                your account.
              </li>
              <li>
                If you are a new user and need to create an account, then click the Sign-up button
                and then click on the "Sign up" link. Then, follow the steps for sign up.
              </li>
            </ul>
            <div className='mt-4'>
              If you have not signed for more than 180 days, then your account may be deactivated.
              Accounts are deactivated after 180 days of inactivity. To reactivate your account,
              send an activation email to yourself.
              <Form name='userActivateForm' noValidate validated={isFormValidated}>
                <Form.Group className='mt-2'>
                  <Form.Control
                    type='text'
                    onChange={(event) => setActivateUserId(event.currentTarget.value)}
                    name='emailInputControl'
                    placeholder='Enter Email'
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    Your email address is required to process this activation request.
                  </Form.Control.Feedback>
                </Form.Group>
                <Button
                  className='mt-2'
                  onClick={handleUserActivate}
                  size='sm'
                  variant='primary'
                  disabled={isProcessing}
                >
                  Send Activation Email
                </Button>
                {isProcessing && (
                  <span className='ms-2'>
                    <Spinner className='me-1' animation='border' role='status' size='sm' />
                    Please wait...
                  </span>
                )}
                {doneProcessing && (
                  <Alert className='mt-3' variant='success'>
                    Your request has been processed. Check your email for instructions. If you do
                    not get an email, then you most likely entered an email address that was not
                    used for your CeBONDS account or it is already active.
                    <div className='mt-3 fw-bold'>
                      <FontAwesomeIcon icon={faInfoCircle} className='me-1' />
                      After you receive your email and complete the activation steps, close the My
                      Apps tab on this browser and return to this tab. Then, return to the home page
                      and click the login button on the bottom of the home page.
                    </div>
                  </Alert>
                )}
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleSelfHelpModalHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
