import { useEffect, useContext, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { CeBondsUserContext } from '../contexts/UserContext';
import { Link } from 'react-router-dom';
import LoadingPage from './util/LoadingPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
//import AccountEmailModal from '../components/AccountEmailModal';
import AccountPasswordModal from '../components/AccountPasswordModal';

const AccountPage = () => {
  const ceBondsUser = useContext(CeBondsUserContext);
  //const [newEmail, setNewEmail] = useState<string>();

  useEffect(() => {
    document.title = 'Account - ICE CeBONDS';
    console.log('AccountPage');
  }, []);

  // const handleNewEmail = (newEmail: string | undefined) => {
  //   setNewEmail(newEmail);
  // };

  if (!ceBondsUser) {
    return <LoadingPage message={'Please Wait...'} />;
  }

  return (
    <Container>
      <h3 className='mt-3'>CeBONDS Account</h3>
      {/* <Card className='mt-3 mb-3'>
        <Card.Header>
          <div className='fw-bold'>Username/Email</div>
          <div>Your username/email is used for both login and sending communications to you.</div>
        </Card.Header>
        <Card.Body>
          <div>
            {ceBondsUser?.email}
            {newEmail && (
              <Alert variant='info'>
                <FontAwesomeIcon className='pe-2 fs-4' icon={faInfoCircle} color={'mediumblue'} />
                Check your {newEmail} inbox for a verification message. Click the verify email link
                in the message to complete the username/email address change. Your change will not
                be saved until your new email address is verified. If you did not get the email
                message, verify that the new email address is valid.
              </Alert>
            )}
            <AccountEmailModal onNewEmail={(newEmail) => handleNewEmail(newEmail)} />
          </div>
        </Card.Body>
      </Card> */}
      <Card className='mb-3'>
        <Card.Header className='fw-bold'>Password</Card.Header>
        <Card.Body>
          <AccountPasswordModal />
        </Card.Body>
      </Card>
      <Card className='mb-3'>
        <Card.Header className='fw-bold'>Advanced Settings</Card.Header>
        <Card.Body>
          <div className='mb-1'>
            In addition to changing password, you can change your username, security question, and
            see login activity for your account.
          </div>
          <div>
            <Button
              size='sm'
              href={process.env.REACT_APP_OKTA_SETTINGS_PATH}
              target='_blank'
              rel='noreferrer'
            >
              Advanced <FontAwesomeIcon icon={faUpRightFromSquare} className='ms-1' />
            </Button>
          </div>
        </Card.Body>
      </Card>
      <div className='mt-3'>
        <Link to='/'>
          <Button className='me-3' variant='primary'>
            Home
          </Button>
        </Link>
      </div>
    </Container>
  );
};

export default AccountPage;
