import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Container, Alert, Table } from 'react-bootstrap';
import SubjectSearchApi from '../api/SubjectSearchApi';
import { BondableRequest } from '../models/bond-data';
import { useCeBondsUserContext } from '../contexts/UserContext';
import LoadingPage from './util/LoadingPage';
import { IApiError, useErrorContext } from '../contexts/ErrorContext';
import Utils from '../api/Utils';
import BondStatusUtil from '../helper/BondStatusUtil';
import { UserAlerts } from '../components/UserAlerts';
import { UnauthenticatedContent } from '../components/UnauthenticatedContent';
import { useOktaAuth } from '@okta/okta-react';
import { UserSelfHelp } from '../components/UserSelfHelp';

export const LandingPage = () => {
  const navigate = useNavigate();
  const [userBondRequests, setUserBondRequests] = useState<BondableRequest[]>();
  const ceBondsUser = useCeBondsUserContext();
  const { oktaAuth, authState } = useOktaAuth();
  const [isHeadDone, setIsHeadDone] = useState(false);

  const error = useErrorContext();

  useEffect(() => {
    document.title = 'Home - ICE CeBONDS';

    console.info('process.env: ', process.env);
    console.info('REACT_APP_SHOW_DAP_SCRIPT: ', process.env.REACT_APP_SHOW_DAP_SCRIPT);

    if (!isHeadDone && process.env.REACT_APP_SHOW_DAP_SCRIPT === 'yes') {
      var dapScript = document.createElement('script');
      dapScript.setAttribute('async', '');
      dapScript.setAttribute('id', '_fed_an_ua_tag');
      dapScript.setAttribute(
        'src',
        'https://dap.digitalgov.gov/Universal-Federated-Analytics-Min.js?agency=DHS',
      );
      dapScript.setAttribute('type', 'text/javascript');
      document.head.appendChild(dapScript);
      setIsHeadDone(true);

      console.info(
        "We participate in the US government's analytics program. See the data at analytics.usa.gov.",
      );
    }

    error.clearError(); // clears error banner on page load
  }, []);

  useEffect(() => {
    if (ceBondsUser?.userId) {
      SubjectSearchApi.getBondsForUser()
        .then((responseData: BondableRequest[] | IApiError) => {
          if (Utils.isError(responseData)) {
            const errorData = responseData as IApiError;
            error.setError(errorData.message);
            setUserBondRequests([]);
          } else if ('length' in responseData) {
            if (responseData.length === 0) {
              setUserBondRequests([]);
            } else {
              setUserBondRequests(responseData);
            }
          }
        })
        .catch((err) => {
          error.setError(`Failed to search: ${err}`);
          setUserBondRequests([]);
          console.error('SubjectSearchApi.getBondsByUserIdApi failed', err);
        });
    }
  }, [ceBondsUser]);

  // const [filteraNumber, setFilteraNumber] = useState<string>('');
  // const onChangeFilteraNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setFilteraNumber(event.target.value);
  // };

  // const handleFilter = () => {
  //   if (ceBondsUser) {
  //     SubjectSearchApi.getBondsByANumber(filteraNumber).then(
  //       (responseData: BondableRequest[] | IApiError) => {
  //         if (Utils.isError(responseData)) {
  //           const errorData = responseData as IApiError;
  //           error.setError(errorData.message);
  //           setUserBondRequests([]);
  //         } else if ('length' in responseData) {
  //           if (responseData.length === 0) {
  //             setUserBondRequests([]);
  //           } else {
  //             setUserBondRequests(responseData);
  //           }
  //         }
  //       },
  //     );
  //   }
  // };

  const handleLogin = () => {
    oktaAuth.signInWithRedirect();
  };

  const handleRowClick = (bondRequestId: number) => {
    navigate('/bond-request', { state: bondRequestId });
  };

  const unauthenticatedView = () => {
    if (!authState?.isAuthenticated) {
      return (
        <div>
          <UnauthenticatedContent />
          <h4>ICE CeBONDS Sign-in</h4>
          <div className='mt-3'>
            <Button onClick={() => handleLogin()} variant='primary'>
              Sign-in or Sign-up
            </Button>
            <UserSelfHelp></UserSelfHelp>
          </div>
        </div>
      );
    }
  };

  const authenticatedView = () => {
    if (authState?.isAuthenticated) {
      if (!userBondRequests) return <LoadingPage message={'Loading bond payment requests...'} />;

      return (
        <div>
          {BondStatusUtil.isListActionNeeded(userBondRequests) && (
            <Alert variant='warning'>
              <FontAwesomeIcon className='me-2' icon={faExclamationCircle} color={'orange'} />
              Action is required on one or more of your bond payment requests below.
            </Alert>
          )}
          {userBondRequests?.length === 0 && (
            <p>
              No recent bond payment requests were found for you. Start a new bond payment request.
            </p>
          )}
          {userBondRequests?.length > 0 && (
            <p>
              Click below on your recent bond payment request(s) or start a new bond payment
              request.
            </p>
          )}
          {userBondRequests && userBondRequests.length > 0 && (
            <Table size='sm' responsive hover>
              <thead>
                <tr>
                  <th>Date Created</th>
                  <th>A-Number</th>
                  <th>Last, First Name</th>
                  <th>Bond Status</th>
                </tr>
              </thead>
              <tbody>
                {userBondRequests?.map((bondRequestItem) => (
                  <tr
                    className={BondStatusUtil.isActionNeeded(bondRequestItem) ? 'action-row' : ''}
                    key={bondRequestItem.bondRequestId}
                    data-testid='result-row'
                    onClick={() => handleRowClick(bondRequestItem.bondRequestId)}
                    role='button'
                  >
                    <td>{Utils.formatDisplayDate(bondRequestItem.createDate)}</td>
                    <td>{bondRequestItem.anumber}</td>
                    <td>
                      {bondRequestItem.lastName}, {bondRequestItem.firstName}
                    </td>
                    <td>
                      {BondStatusUtil.isActionNeeded(bondRequestItem) && (
                        <FontAwesomeIcon
                          className='me-1'
                          icon={faExclamationCircle}
                          color={'orange'}
                        />
                      )}
                      {BondStatusUtil.getBondStatusDescription(bondRequestItem)}{' '}
                      <FontAwesomeIcon icon={faChevronRight} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <div className='pt-3'>
            <Link to='/subject'>
              <Button variant='primary'>
                <FontAwesomeIcon icon={faPlus} className='me-1' /> Bond Payment Request
              </Button>
            </Link>
          </div>
        </div>
      );
    }
  };

  return (
    <Container>
      <h3 className='pt-3 pb-3' text-align='center'>
        Welcome to ICE CeBONDS
      </h3>
      <UserAlerts />
      {unauthenticatedView()}
      {authenticatedView()}
    </Container>
  );
};
