import React, { useEffect } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { BondableRequest } from '../models/bond-data';
import { Link, useLocation } from 'react-router-dom';
import { ICeBondsSubject } from '../api/types';
import { SubjectDisplay } from '../components/SubjectDisplay';
import BondStatusUtil from '../helper/BondStatusUtil';
import Utils from '../api/Utils';

interface BondStatusProps {
  bondRequestList: BondableRequest[];
}

export const BondStatus = ({ bondRequestList }: BondStatusProps) => {
  const location = useLocation();
  const ceBondsSubject = location.state as ICeBondsSubject;

  useEffect(() => {
    document.title = 'Your Bond Payment Requests for Alien - ICE CeBONDS';
  }, []);

  return (
    <Container>
      <h4>Your Bond Payment Requests for Alien</h4>
      {bondRequestList.length > 0 && bondRequestList[0].firstName && (
        <div>
          <div className='mb-3'>
            <FontAwesomeIcon className='me-1' icon={faExclamationCircle} color={'orange'} />
            You already have active bond payment requests for this alien. You cannot create a new
            bond payment request at this time for this alien. Please review your bond payment
            request(s) for this alien, and take desired actions, if available.
          </div>
          <SubjectDisplay ceBondsSubject={ceBondsSubject} />
          <Table size='sm' responsive striped borderless hover>
            <thead>
              <tr>
                <th>Date Created</th>
                <th>Bond Status</th>
              </tr>
            </thead>
            <tbody>
              {bondRequestList?.map((bondRequestItem) => (
                <tr key={bondRequestItem.bondRequestId} data-testid='result-row'>
                  <td>{Utils.formatDisplayDate(bondRequestItem.createDate)}</td>
                  <td>
                    <Link
                      className='fw-bold'
                      to='/bond-request'
                      state={bondRequestItem.bondRequestId}
                    >
                      {BondStatusUtil.getBondStatusDescription(bondRequestItem)}
                      <FontAwesomeIcon icon={faChevronRight} className='ms-1' />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <div className='mt-3'>
        <Link to='/'>
          <Button variant='primary' className='me-3'>
            Home
          </Button>
        </Link>
      </div>
    </Container>
  );
};
