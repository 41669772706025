import { useState } from 'react';
import { Alert, Button, Modal, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import OktaUserApi from '../api/OktaUserApi';
import PasswordInput from '../components/form/PasswordInput';
import { IPasswordChanges } from '../api/types';

const AccountPasswordModal = () => {
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [curPassword, setCurPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [isError, setIsError] = useState(false);

  const showPasswordModal = () => {
    setIsPasswordModalVisible(true);
  };

  const hidePasswordModal = () => {
    setIsFormValidated(false);
    setIsPasswordModalVisible(false);
  };

  const handleOnCurPasswordChange = (changedPwd: string) => {
    setCurPassword(changedPwd);
  };

  const handleOnNewPasswordChange = (changedPwd: string) => {
    setNewPassword(changedPwd);
  };

  const savePassword = (event: { currentTarget: any }) => {
    const form = event.currentTarget.form;
    setIsError(false);

    if (form?.checkValidity()) {
      setIsProcessing(true);

      if (curPassword && newPassword) {
        const passwordChanges: IPasswordChanges = {
          oldPassword: curPassword,
          newPassword: newPassword,
        };

        OktaUserApi.savePassword(passwordChanges)
          .then((response) => {
            setIsProcessing(false);
            setIsFormValidated(false);
            if (response.status === 200) {
              hidePasswordModal();
            } else if (response.status === 400) {
              setIsError(true);
            } else {
              console.error('failed with status', response.status);
            }
          })
          .catch((err) => {
            console.error('failed', err);
          });
      }
    }

    setIsFormValidated(true);
  };

  return (
    <>
      <Button className='ms-2' onClick={showPasswordModal} size='sm'>
        Change Password
      </Button>
      <Modal show={isPasswordModalVisible} onHide={hidePasswordModal}>
        <Form name='userPasswordForm' noValidate validated={isFormValidated}>
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-3'>
              Change your CeBONDS account password. Enter your current password to verify your
              identity followed by your new password.
            </div>
            <div className='mb-3'>
              Password requirements:
              <ul>
                <li>At least 12 characters</li>
                <li>A lowercase letter</li>
                <li>An uppercase letter</li>
                <li>A number</li>
                <li>A symbol</li>
                <li>No parts of your username</li>
                <li>Does not include your first name</li>
                <li>Does not include your last name</li>
                <li>Password can't be the same as your last 8 passwords</li>
              </ul>
            </div>
            <div>
              <div className='mt-4'>
                <Form.Group className='mt-2'>
                  <Form.Label>Current Password</Form.Label>
                  <PasswordInput onPasswordChange={handleOnCurPasswordChange} />
                </Form.Group>
                <Form.Group className='mt-2'>
                  <Form.Label>New Password</Form.Label>
                  <PasswordInput onPasswordChange={handleOnNewPasswordChange} enforcePolicy />
                </Form.Group>
              </div>
            </div>
            {isError && (
              <Alert className='mt-3' variant='danger'>
                There was an issue updating your password. Make sure that you correctly entered your
                Current Password and that your New Password meets the password requirements.
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary' onClick={savePassword} disabled={isProcessing}>
              Save Password
            </Button>
            <Button variant='secondary' onClick={hidePasswordModal}>
              Cancel
            </Button>
            <div>
              {isProcessing && (
                <span className='ms-2'>
                  <Spinner className='me-1' animation='border' role='status' size='sm' />
                  Please wait...
                </span>
              )}
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AccountPasswordModal;
