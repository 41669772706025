import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { BondableRequest } from '../models/bond-data';
import { useEffect, useState } from 'react';
import { IBmisObligor, ICommonAddress } from '../api/types';
import LoadingPage from './util/LoadingPage';
import { useErrorContext } from '../contexts/ErrorContext';
import SubjectSearchApi from '../api/SubjectSearchApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ObligorApi from '../api/ObligorApi';
import Utils from '../api/Utils';
import { BondStatusEnum } from '../models/status';
import USStateSelect from '../components/form/USStateSelect';
import AcsFeedbackModal from '../components/AcsFeedbackModal';
import { IAcsCandidate } from '../models/acsCadidate';

export const ReviewAddressPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const error = useErrorContext();

  const bondRequestId = location.state as number;
  const [bondRequest, setBondRequest] = useState<BondableRequest>();
  const [bmisObligor, setBmisObligor] = useState<IBmisObligor>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [editAddressValidated, setEditAddressValidated] = useState(false);
  const [addressToVerify, setAddressToVerify] = useState<ICommonAddress>();

  const [stateCd, setStateCd] = useState<string>();
  const nameAddrRegex = '^[a-zA-Z0-9,\\.\\(\\):;#\'"\\- ]{1,35}$'; // Allows letters, numbers ,.():;#'"- and space.. Most special chars are not allowed. Allows 1 to 35 character length.
  const cityRegex = "^[a-zA-Z,\\.'\\- ]{1,18}$"; // Allows letters, numbers ,.'- and space.. Most special chars are not allowed. Allows 1 to 18 character length.
  const zipRegex = '^\\d{5}[\\-]?(\\d{4})?$'; // Requires 5 digits. Plus4 portion is optional.

  const isCorrect = () => {
    return bondRequest?.statusCode.code === BondStatusEnum.REFUND_ADDR_CORRECT;
  };

  useEffect(() => {
    error.clearError(); // clears error banner on page load
  }, []);

  useEffect(() => {
    document.title = 'Review Obligor Address - ICE CeBONDS';

    // Get fresh bondRequest object
    if (bondRequestId) {
      SubjectSearchApi.GetBondRequest(bondRequestId).then((cbr) => {
        setBondRequest(cbr);
      });
    }
  }, [bondRequestId]);

  useEffect(() => {
    if (bondRequest) {
      ObligorApi.getBmisObligorForBond(bondRequest.bondRequestId).then((bo) => {
        if (bo) {
          setBmisObligor(bo);
        }
      });
    }
  }, [bondRequest]);

  const handleCorrectAddress = () => {
    if (bondRequest) {
      setIsSubmitting(true);
      SubjectSearchApi.addressCorrect(bondRequest.bondRequestId).then((cbr) => {
        if (Utils.isError(cbr)) {
          error.setError('Unexpected error encountered. [ac]');
          setIsSubmitting(false);
        } else {
          navigate('/bond-request', { state: bondRequest.bondRequestId });
        }
      });
    }
  };

  const handleAddressSave = (event: { currentTarget: any }) => {
    const form = event.currentTarget.form;

    if (form.checkValidity()) {
      const addressToVerify: ICommonAddress = {
        street1: form.adr1Control.value,
        street2: form.adr2Control.value,
        street3: '',
        city: form.cityControl.value,
        stateCd: stateCd || '',
        zip: form.zipCodeControl.value.replaceAll('-', ''),
        countryCd: '',
      };
      setAddressToVerify(addressToVerify); // This will trigger the ACS Feedback to verify the address
    }

    setEditAddressValidated(true);
  };

  const handleAcsFeedbackAccept = (acceptedAcsCandidate?: IAcsCandidate) => {
    // We have a good address. Save obligor with good address applied.
    if (acceptedAcsCandidate) {
      const newAddress: ICommonAddress = {
        street1: acceptedAcsCandidate.delivery_line_1,
        street2: '',
        street3: '',
        city: acceptedAcsCandidate.components.city_name,
        stateCd: acceptedAcsCandidate.components.state_abbreviation,
        zip: acceptedAcsCandidate.components.zipcode + acceptedAcsCandidate.components.plus4_code,
        countryCd: '',
      };
      saveAddress(newAddress);
    }
  };

  const handleAcsFeedbackReject = () => {
    // Do nothing
  };

  const saveAddress = (newAddress: ICommonAddress) => {
    if (newAddress) {
      setIsSubmitting(true);
      ObligorApi.saveAddress(bondRequest?.bondRequestId, newAddress).then((response) => {
        if (response.status === 200) {
          //ar obj = response.json();
          navigate('/bond-request', { state: bondRequest?.bondRequestId });
        } else {
          error.setError('Unexpected error encountered. [has]');
          setIsSubmitting(false);
        }
      });
    }
  };

  if (!bondRequest || isSubmitting) {
    return <LoadingPage message={'Please Wait...'} />;
  }

  return (
    <Container className='mt-3'>
      <h4>Review Obligor Address</h4>
      <div className='mb-3'>
        Review your postal mailing address. Your bond refund check will be mailed to this address.
      </div>
      <div className='mb-3'></div>
      <div className='mb-3'>
        <Card className='mb-3'>
          <Card.Header className='fw-bold'>Current Mailing Address</Card.Header>
          <Card.Body>
            {!bmisObligor && (
              <div className='pb-3'>
                <Spinner animation='border' role='status' size='sm' className='me-1' />
                Loading Address. Please wait...
              </div>
            )}
            {bmisObligor && (
              <div>
                <div className='pb-3'>
                  This is the address that your bond refund check will be mailed to. If it is
                  correct, then click the button to Use This Address. Note: The post office will not
                  forward checks.
                </div>
                <div className='p-0'>{bmisObligor.streetAddressOne}</div>
                {bmisObligor.streetAddressTwo && (
                  <div className='p-0'>{bmisObligor.streetAddressTwo}</div>
                )}
                {bmisObligor.streetAddressThree && (
                  <div className='p-0'>{bmisObligor.streetAddressThree}</div>
                )}
                <div className='p-0'>
                  {bmisObligor.city}, {bmisObligor.state} {bmisObligor.zipCode}
                </div>
                {!isCorrect() && (
                  <Button
                    size='sm'
                    className='mt-3'
                    onClick={handleCorrectAddress}
                    disabled={isCorrect()}
                  >
                    Use This Address
                  </Button>
                )}
                {isCorrect() && (
                  <div className='pt-2'>
                    <FontAwesomeIcon className='pe-1' icon={faInfoCircle} color={'mediumblue'} />
                    You have already confirmed that this address is correct. You may change it again
                    if needed.
                  </div>
                )}
              </div>
            )}
            <hr />
            <div>
              <div className='pb-3'>
                If the address above is NOT correct, then provide a new mailing address below that
                you want your bond refund check mailed to.
              </div>
              <Form name='obligorEditForm' noValidate validated={editAddressValidated}>
                <Form.Group className='mb-2'>
                  <Form.Control
                    type='text'
                    id='adr1Control'
                    placeholder='Street 1'
                    required
                    pattern={nameAddrRegex}
                    maxLength={35}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter a valid street. Special characters are not allowed.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mb-2'>
                  <Form.Control
                    type='text'
                    name='adr2Control'
                    placeholder='Street 2'
                    className='mb-2'
                    pattern={nameAddrRegex}
                    maxLength={35}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter a valid street. Special characters are not allowed.
                  </Form.Control.Feedback>
                </Form.Group>
                <Row className='mb-2'>
                  <Form.Group as={Col} md='6' controlId='validationCustom03'>
                    <Form.Control
                      type='text'
                      name='cityControl'
                      placeholder='City'
                      required
                      pattern={cityRegex}
                      maxLength={18}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Please provide a valid city.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md='3' controlId='validationCustom04'>
                    <USStateSelect
                      required
                      invalidFeedback={'State is required'}
                      onChange={(state) => setStateCd(state.abbreviation)}
                      id={'stateInput'}
                      mini={true}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md='3' controlId='validationCustom05'>
                    <Form.Control
                      type='text'
                      name='zipCodeControl'
                      placeholder='Zip'
                      required
                      pattern={zipRegex}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Please provide a valid zip.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Button size='sm' className='me-3' onClick={handleAddressSave}>
                  Save New Address
                </Button>
                <div className='small mt-2'>
                  Note: this address will be saved for future bond payment requests.
                </div>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className='pt-3'>
        <Link to='/bond-request' state={bondRequest.bondRequestId}>
          <Button variant='secondary' className='me-3'>
            <FontAwesomeIcon className='pe-2' icon={faChevronLeft} />
            Back
          </Button>
        </Link>
      </div>
      <AcsFeedbackModal
        addressToVerify={addressToVerify}
        onAccept={(acceptedAcsCanidate) => handleAcsFeedbackAccept(acceptedAcsCanidate)}
        onReject={() => handleAcsFeedbackReject()}
      />
    </Container>
  );
};
