import { Link, useLocation } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  Container,
  Form,
  ListGroup,
  Modal,
  Spinner,
  Table,
} from 'react-bootstrap';
import { BondPayment, BondableRequest } from '../models/bond-data';
import { useEffect, useState } from 'react';
import { IBondRequestComments, ICeBondsSubject, IBondRequestDocument } from '../api/types';
import CommentsApi from '../api/CommentsApi';
import ObligorDocumentApi from '../api/ObligorDocumentApi';
import Utils from '../api/Utils';
import LoadingPage from './util/LoadingPage';
import BondStatusUtil from '../helper/BondStatusUtil';
import { IApiError, useErrorContext } from '../contexts/ErrorContext';
import { BondStatusEnum, statusesForComments, statusesForWithdraw } from '../models/status';
import SubjectSearchApi from '../api/SubjectSearchApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faDownload,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { RequestSubmittedProgress } from '../components/progress/RequestSubmittedProgress';
import { SignContractAction } from '../components/progress/SignContractAction';
import { CurrentStatusInfo } from '../components/progress/CurrentStatusInfo';
import { UploadPaymentAction } from '../components/progress/UploadPaymentAction';
import SubmitContractAction from '../components/progress/SubmitContractAction';
import { PaymentProgress } from '../components/progress/PaymentProgress';
import { ContractProgress } from '../components/progress/ContractProgress';
import PaymentApi from '../api/PaymentApi';
import { NoticeProgress } from '../components/progress/NoticeProgress';
import { NoticeAction } from '../components/progress/NoticeAction';
import { AddressAction } from '../components/progress/AddressAction';
import BondDocUtil from '../helper/BondDocUtil';
import { I220BAction } from '../components/progress/I220BAction';
import { I220BProgress } from '../components/progress/I220BProgress';

export const BondRequestPage = () => {
  const location = useLocation();
  const error = useErrorContext();

  const bondRequestId = location.state as number;
  const [bondRequest, setBondRequest] = useState<BondableRequest>();
  const [ceBondsSubject, setCeBondsSubject] = useState<ICeBondsSubject>();
  const [bondRequestComments, setBondRequestComments] = useState<IBondRequestComments>();
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [isCommentSaving, setIsCommentSaving] = useState<boolean>(false);
  const [isCommentsLoading, setIsCommentsLoading] = useState<boolean>(false);
  const [downloadingId, setDownloadingId] = useState<number>();
  const [fileControlFeedback, setFileControlFeedback] = useState<string>();
  const [canDoWithdraw, setCanDoWithdraw] = useState<boolean>(false);

  const [bondDocList, setBondDocList] = useState<IBondRequestDocument[]>();
  const [bondNoticeList, setBondNoticeList] = useState<IBondRequestDocument[]>();
  const [bondI220B, setBondI220B] = useState<IBondRequestDocument>();
  const [bondContract, setBondContract] = useState<IBondRequestDocument>();
  const [bondObligorDoc, setBondObligorDoc] = useState<IBondRequestDocument>();
  const [bondPayment, setBondPayment] = useState<BondPayment>();
  const [isPaymentDocloading, setIsPaymentDocloading] = useState<boolean>(false);

  useEffect(() => {
    error.clearError(); // clears error banner on page load
  }, []);

  useEffect(() => {
    document.title = 'Bond Payment Request - ICE CeBONDS';

    // Get fresh bondRequest object
    if (bondRequestId) {
      SubjectSearchApi.GetBondRequest(bondRequestId).then((cbr) => {
        setBondRequest(cbr);
      });

      if (!bondRequestComments) {
        refreshComments();
      }
    }
  }, [bondRequestId]);

  useEffect(() => {
    if (bondRequestId) {
      ObligorDocumentApi.getBondDocumentList(bondRequestId).then((docList) => {
        if (docList) {
          setBondDocList(docList);
        }
      });
    }
  }, [bondRequestId]);

  useEffect(() => {
    if (bondDocList && !bondNoticeList) {
      // get the list of notices for this bond
      var noticeList: IBondRequestDocument[] = new Array();

      bondDocList.forEach((doc) => {
        if (BondDocUtil.isNotice(doc)) {
          noticeList?.push(doc);
        }
        if (doc.typeCode.code === 'I220B') {
          setBondI220B(doc);
        }
      });

      setBondNoticeList(noticeList);
    }
  }, [bondDocList, bondNoticeList]);

  useEffect(() => {
    if (bondDocList && !bondContract) {
      // get the most recent I-352
      var mostRecentDoc: IBondRequestDocument | undefined;

      bondDocList.forEach((doc) => {
        if (doc.typeCode.code === 'I352') {
          if (mostRecentDoc === undefined) {
            mostRecentDoc = doc;
          } else if (doc.bondReqDocId > mostRecentDoc.bondReqDocId) {
            mostRecentDoc = doc;
          }
        }
      });

      if (mostRecentDoc !== undefined) {
        setBondContract(mostRecentDoc);
      }
    }
  }, [bondDocList, bondContract]);

  useEffect(() => {
    if (bondDocList && !bondObligorDoc) {
      // get the most recent obligor doc
      var mostRecentDoc: IBondRequestDocument | undefined;

      bondDocList.forEach((doc) => {
        if (doc.obligorDocInd) {
          if (mostRecentDoc === undefined) {
            mostRecentDoc = doc;
          } else if (doc.bondReqDocId > mostRecentDoc.bondReqDocId) {
            mostRecentDoc = doc;
          }
        }
      });

      if (mostRecentDoc !== undefined) {
        setBondObligorDoc(mostRecentDoc);
      }
    }
  }, [bondDocList, bondObligorDoc]);

  useEffect(() => {
    if (bondRequest) {
      setIsPaymentDocloading(true);
      PaymentApi.getBondPayment(bondRequest.bondRequestId).then((proofDoc) => {
        setBondPayment(proofDoc);
        setIsPaymentDocloading(false);
      });

      setCeBondsSubject({
        firstName: bondRequest.firstName,
        lastName: bondRequest.lastName,
        aNumber: bondRequest.anumber,
      });
      setCanDoWithdraw(statusesForWithdraw.includes(bondRequest.statusCode.code));
    }
  }, [bondRequest]);

  const refreshComments = () => {
    if (bondRequestId) {
      setIsCommentsLoading(true);
      CommentsApi.getComments(bondRequestId).then((comments) => {
        if (!Utils.isError(comments)) {
          setBondRequestComments(comments as IBondRequestComments);
          setIsCommentsLoading(false);
        }
      });
    }
  };

  const cleanupCommentModal = () => {
    setIsCommentSaving(false);
    setShowCommentModal(false);
  };

  const handleShowCommentModal = () => {
    setCommentValidated(false);
    setShowCommentModal(true);
  };

  const handleHideCommentModal = () => setShowCommentModal(false);

  const [commentValidated, setCommentValidated] = useState(false);
  const handleCommentSave = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    const form = event.currentTarget.form;
    const fileControl = form?.fileSelectControl;
    const file = fileControl.files[0];
    error.clearError();

    fileControl.setCustomValidity('');
    if (file) {
      if (file.size > 20000000) {
        // file size limit is 20 MB
        setFileControlFeedback('The selected file is too large. The file size limit is 20 MB.');
        fileControl.setCustomValidity('TooLarge');
      } else if (
        file.type !== 'application/pdf' &&
        file.type !== 'image/png' &&
        file.type !== 'image/jpg' &&
        file.type !== 'image/jpeg'
      ) {
        setFileControlFeedback(
          'Type of selected file is not accepted. Accepted types: .pdf, .png, .jpg',
        );
        fileControl.setCustomValidity('TypeNotAccepted');
      }
    }
    fileControl.reportValidity();

    if (form?.checkValidity()) {
      // save comment info
      setIsCommentSaving(true);

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        console.log('file ', file, fileControl);

        console.log('uploading file...');
        CommentsApi.addCommentAttachment(bondRequestId, formData)
          .then((response) => {
            if (Utils.isError(response)) {
              const errResponse = response as IApiError;
              error.setError(errResponse.message);
            } else if ('drsId' in response) {
              CommentsApi.addComment(
                bondRequestId,
                form.commentControl.value,
                response.drsId,
                response.docType,
              ).then(refreshComments);
            }
          })
          .finally(cleanupCommentModal);
      } else {
        CommentsApi.addComment(bondRequestId, form.commentControl.value)
          .then(() => {
            cleanupCommentModal();
          })
          .finally(refreshComments);
      }
    }

    setCommentValidated(true);
  };

  const downloadCommentAttachment = (commentId: number | undefined) => {
    if (commentId) {
      setDownloadingId(commentId);
      CommentsApi.downloadCommentAttachment(commentId).then((blob) => {
        var fileName = 'cebonds-comment-file.' + Utils.getFileExtensionFromBlob(blob);
        Utils.downloadBlob(blob, fileName);
        setDownloadingId(undefined);
      });
    }
  };

  const handleSubmittedRequest = (submittedBondRequest: BondableRequest | undefined) => {
    setBondRequest(submittedBondRequest);
  };

  const senderCodeText = (senderCode: string) => {
    if (senderCode === 'ERO' || senderCode === 'ICE') {
      return 'ICE';
    } else if (senderCode === 'Obligor') {
      return 'Obligor';
    }

    return '?senderCode?';
  };

  if (!bondRequest || !bondRequestComments || !bondDocList || isPaymentDocloading) {
    return <LoadingPage message={'Please Wait...'} />;
  }

  return (
    <Container className='mt-3'>
      <h4>Bond Payment Request</h4>
      <div className='mb-3'>
        Review your progress and comments below. Current Status:{' '}
        <em>{BondStatusUtil.getBondStatusDescription(bondRequest)}</em>
      </div>
      {BondStatusUtil.isActionNeeded(bondRequest) && (
        <Alert variant='warning'>
          <FontAwesomeIcon className='me-2' icon={faExclamationCircle} color={'orange'} />
          Action is required on this bond payment request.
        </Alert>
      )}
      <Card className='mb-3'>
        <Card.Header>
          <Card.Title>Your Progress</Card.Title>
        </Card.Header>
        <ListGroup variant='flush'>
          <RequestSubmittedProgress bondObligorDoc={bondObligorDoc} bondRequest={bondRequest} />
          <I220BProgress bondI220B={bondI220B} />
          <ContractProgress bondContract={bondContract} bondRequest={bondRequest} />
          <PaymentProgress bondPayment={bondPayment} />
          <NoticeProgress bondNoticeList={bondNoticeList} />
          <CurrentStatusInfo bondRequest={bondRequest} />
          <I220BAction bondRequest={bondRequest} bondI220B={bondI220B} />
          <SignContractAction bondRequest={bondRequest} bondI220B={bondI220B} />
          <UploadPaymentAction bondRequest={bondRequest} bondPayment={bondPayment} />
          <SubmitContractAction
            bondPayment={bondPayment}
            bondRequest={bondRequest}
            onSubmitted={(submittedBondRequest: BondableRequest | undefined) =>
              handleSubmittedRequest(submittedBondRequest)
            }
          />
          <NoticeAction bondNoticeList={bondNoticeList} />
          <AddressAction bondRequest={bondRequest} />
        </ListGroup>
      </Card>
      <Card className='mb-3'>
        <Card.Header>
          <Card.Title>Comments</Card.Title>
          <Card.Subtitle>Send comments to ICE regarding this bond payment request</Card.Subtitle>
        </Card.Header>
        {bondRequestComments.comments.length > 0 && (
          <Card.Body>
            <Table size='sm' responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Sender</th>
                  <th>Comment</th>
                  <th>Attachment</th>
                </tr>
              </thead>
              <tbody>
                {bondRequestComments.comments.map((bondRequestComment) => (
                  <tr key={bondRequestComment.id} data-testid='result-row'>
                    <td>
                      {new Intl.DateTimeFormat('default').format(
                        Date.parse(bondRequestComment.created),
                      )}
                    </td>
                    <td>{senderCodeText(bondRequestComment.senderCode)}</td>
                    <td>{bondRequestComment.comment}</td>
                    <td>
                      {bondRequestComment.drsId && (
                        <Button
                          className='p-0 small'
                          variant={'link'}
                          onClick={() => downloadCommentAttachment(bondRequestComment.id)}
                        >
                          <small>
                            <span>Download</span>
                            <FontAwesomeIcon className='ps-1' icon={faDownload} title='Download' />
                          </small>
                        </Button>
                      )}
                      {!bondRequestComment.drsId && <span>None</span>}
                      {downloadingId === bondRequestComment.id && (
                        <small>
                          <Spinner animation='border' role='status' size='sm' />
                        </small>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        )}
        <Card.Footer>
          {isCommentsLoading && (
            <div className='pb-3'>
              <Spinner animation='border' role='status' size='sm' className='me-1' />
              Loading...
            </div>
          )}
          {statusesForComments.includes(bondRequest.statusCode.code) && (
            <Button size='sm' variant='secondary' onClick={handleShowCommentModal}>
              Add Comment
            </Button>
          )}
          {!statusesForComments.includes(bondRequest.statusCode.code) && (
            <small>
              New comments cannot be added at this time. The current status of this bond payment
              request does not allow for new comments.
            </small>
          )}
        </Card.Footer>
      </Card>
      <div className='mb-3'>
        <div>
          <span className='fw-bold'>Alien: </span>
          {ceBondsSubject?.lastName}, {ceBondsSubject?.firstName}
        </div>
        <div>
          <span className='fw-bold'>A-Number: </span>
          {ceBondsSubject?.aNumber}
        </div>
        {bondRequest.earmBondNumber && (
          <div>
            <span className='fw-bold'>Bond Number: </span>
            {bondRequest.earmBondNumber}
          </div>
        )}
        {canDoWithdraw && bondRequest.statusCode.code !== BondStatusEnum.BOND_WITHDRAWN && (
          <div>Withdrawal window is now open.</div>
        )}
        {!canDoWithdraw && bondRequest.statusCode.code !== BondStatusEnum.BOND_WITHDRAWN && (
          <div>Withdrawal window is closed.</div>
        )}
      </div>
      <div className='mb-3'>
        <div className='fw-bold'>ICE Forms</div>
        <div>
          <a href='https://www.ice.gov/doclib/forms/i352a.pdf' target='_blank' rel='noreferrer'>
            I-352A IRS Withholding Notice <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </a>
        </div>
        <div>
          <a href='https://www.ice.gov/doclib/forms/i333.pdf' target='_blank' rel='noreferrer'>
            I-333 Obligor Change of Address <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </a>
        </div>
      </div>
      <div className='pt-1'>
        <Link to='/'>
          <Button variant='primary' className='me-3'>
            Home
          </Button>
        </Link>
        {canDoWithdraw && (
          <Link to='/withdraw-bond-request' state={bondRequest}>
            <Button variant='secondary' className='me-3'>
              Withdraw Request
            </Button>
          </Link>
        )}
      </div>
      <Modal show={showCommentModal} onHide={handleHideCommentModal}>
        <Form name='commentForm' noValidate validated={commentValidated}>
          <Modal.Header closeButton>
            <Modal.Title>Add Comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form.Group className='mb-3'>
                <Form.Label>
                  Your Comment{' '}
                  <span className='text-muted'>(Maximum allowed characters is 4000)</span>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  name='commentControl'
                  required
                  maxLength={4000}
                  rows={4}
                />
                <Form.Control.Feedback type='invalid'>
                  Please enter a comment.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId='formFile' className='mb-1'>
                <Form.Label>Optional Attachment</Form.Label>
                <Form.Control
                  type='file'
                  name='fileSelectControl'
                  accept='application/pdf, image/png, image/jpg, image/jpeg'
                />
                <Form.Control.Feedback type='invalid'>{fileControlFeedback}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isCommentSaving && (
              <div>
                <Spinner animation='border' role='status' size='sm' className='me-1' />
                Please Wait...
              </div>
            )}
            <Button variant='primary' onClick={handleCommentSave} disabled={isCommentSaving}>
              Add
            </Button>
            <Button variant='secondary' onClick={handleHideCommentModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};
