import { Link } from 'react-router-dom';
import { faChevronRight, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ListGroup, Stack } from 'react-bootstrap';
import { IBondRequestDocument } from '../../api/types';
import { BondableRequest } from '../../models/bond-data';
import { BondStatusEnum } from '../../models/status';
import { BondStatusContent } from '../BondStatusContent';

export const I220BAction = ({
  bondRequest,
  bondI220B,
}: {
  bondRequest: BondableRequest;
  bondI220B: IBondRequestDocument | undefined;
}) => {
  // if the request is ready for payment and there is a I220B and it is not viewed, then it needs this action
  if (
    bondRequest.statusCode.code === BondStatusEnum.APPROVED_BONDABLE_REQUEST &&
    bondI220B &&
    !bondI220B.viewedDate
  ) {
    return (
      <>
        <ListGroup.Item variant='warning'>
          <Stack direction='horizontal'>
            <div>
              <FontAwesomeIcon className='pe-3 fs-2' icon={faExclamationCircle} color={'orange'} />
            </div>
            <div>
              <div>
                <div className='fw-bold'>Action Required: </div>
                <div>
                  <BondStatusContent bondStatusCode={bondRequest.statusCode.code} />
                  <br />
                  At this point, you must view the form I-220B related to this request before
                  proceeding with the bond contract and payment. Click the View button below.
                  <br />
                  <b>Note:</b> Viewing the I-220B, the completion of the I-352 contract, and bond
                  payment should be done immediately to help ensure the timely processing of the
                  bond and release of the alien.
                </div>
              </div>
              <div className='mt-2 mb-2'>
                <Link to='/notice' state={bondI220B}>
                  <Button>
                    View I-220B
                    <FontAwesomeIcon className='ps-2' icon={faChevronRight} />
                  </Button>
                </Link>
              </div>
            </div>
          </Stack>
        </ListGroup.Item>
      </>
    );
  }

  return <></>;
};
