import React, { useEffect, useState } from 'react';
import { Button, Col, InputGroup, Modal, Row, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ObligorApi from '../api/ObligorApi';
import { ICommonAddress, IObligor, IObligorInfo, IObligorType } from '../api/types';
import { useErrorContext } from '../contexts/ErrorContext';
import USStateSelect from './form/USStateSelect';
import { IAcsCandidate } from '../models/acsCadidate';
import AcsFeedbackModal from './AcsFeedbackModal';

export interface IObligorEditModal {
  onSave: (savedObligor: IObligor) => void;
  obligorEmail?: string;
  obligor?: IObligor;
  buttonText: string;
}

const ObligorEditModal = ({ onSave, obligorEmail, obligor, buttonText }: IObligorEditModal) => {
  const [obligorTypeList, setObligorTypeList] = useState<IObligorType[]>();
  const [isObligorSaving, setIsObligorSaving] = useState<boolean>(false);
  const [obligorTypeCd, setObligorTypeCd] = useState<string>();
  const [obligorStateCd, setObligorStateCd] = useState<string>();
  const [showObligorModal, setShowObligorModal] = useState(false);
  const [editObligorValidated, setEditObligorValidated] = useState(false);
  const [addressToVerify, setAddressToVerify] = useState<ICommonAddress>();
  const [obligorToSaveCopy, setObligorToSaveCopy] = useState<IObligorInfo>();

  const [ssnDefault, setSSNDefault] = useState('');
  const [anumberDefault, setANumberDefault] = useState('');
  const [einDefault, setEINDefault] = useState('');
  const error = useErrorContext();

  const nameAddrRegex = '^[a-zA-Z0-9,\\.\\(\\):;#\'"\\- ]{1,35}$'; // Allows letters, numbers ,.():;#'"- and space.. Most special chars are not allowed. Allows 1 to 35 character length.
  const cityRegex = "^[a-zA-Z,\\.'\\- ]{1,18}$"; // Allows letters, numbers ,.'- and space.. Most special chars are not allowed. Allows 1 to 18 character length.
  const ssnRegex = '^\\d{3}[\\-]?\\d{2}[\\-]?\\d{4}$'; // Requires 9 digit SSN. Formatting is optional.
  const einRegex = '^\\d{2}[\\-]?\\d{7}$'; // Requires 9 digit EIN. Formatting is optional.
  const iTinOrANumberRegex = '^\\d{3}[\\-]?\\d{3}[\\-]?\\d{3}$'; // Requires 9 digits in either ###-###-#### or ###-###-### format. Formatting is optional.
  const phoneRegex = '^\\(?\\d{3}\\)?[ \\.\\-]?\\d{3}[ \\.\\-]?\\d{4}$'; // Requires 10 digit phone. Formatting is optional.
  const zipRegex = '^\\d{5}[\\-]?(\\d{4})?$'; // Requires 5 digits. Plus4 portion is optional.

  useEffect(() => {
    ObligorApi.getObligorTypes().then((obligorTypes) => setObligorTypeList(obligorTypes));
  }, []);

  const obligorIsOrg = (obligor: IObligor | undefined) => {
    if (obligor) {
      return obligor.typeCode?.code === 'NonProfit' || obligor.typeCode?.code === 'LawFirm';
    }

    return false;
  };

  const getButtonVariant = (buttonText: string | undefined) => {
    if (buttonText && buttonText === 'Add') {
      return 'primary';
    }

    return 'secondary';
  };

  const onChangeObligorTypeCd = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setObligorTypeCd(event.target.value);
  };

  const handleObligorModalClose = () => {
    setShowObligorModal(false);
  };

  const handleShowObligorModal = () => {
    setObligorTypeCd(obligor?.typeCode?.code);
    setObligorStateCd(obligor?.stateCd);

    if (obligor?.typeCode?.code === 'Citizen') {
      setSSNDefault(obligor.einTinNbr);
    }
    if (obligor?.typeCode?.code === 'LPR') {
      setSSNDefault(obligor.einTinNbr);
    }
    if (obligor?.typeCode?.code === 'NonCitizen') {
      setANumberDefault(obligor.einTinNbr);
    }
    if (obligor && obligorIsOrg(obligor)) {
      setEINDefault(obligor.einTinNbr);
    }

    setEditObligorValidated(false);
    setShowObligorModal(true);
  };

  const handleAcsFeedbackAccept = (acceptedAcsCandidate?: IAcsCandidate) => {
    // We have a good address. Save obligor with good address applied.
    if (obligorToSaveCopy && acceptedAcsCandidate) {
      const obligorWithAcsCandidate = getObligorWithAcsCandidate(
        obligorToSaveCopy,
        acceptedAcsCandidate,
      );
      saveObligor(obligorWithAcsCandidate);
    }
  };

  const handleAcsFeedbackReject = () => {
    // Do nothing
  };

  const getObligorWithAcsCandidate = (obligor: IObligorInfo, acsCandidate: IAcsCandidate) => {
    let obligorWithAcsCandidate = obligor;

    obligorWithAcsCandidate.street1 = acsCandidate.delivery_line_1;
    obligorWithAcsCandidate.street2 = '';
    obligorWithAcsCandidate.street3 = '';
    obligorWithAcsCandidate.city = acsCandidate.components.city_name;
    obligorWithAcsCandidate.stateCd = acsCandidate.components.state_abbreviation;
    obligorWithAcsCandidate.zip =
      acsCandidate.components.zipcode + acsCandidate.components.plus4_code;

    return obligorWithAcsCandidate;
  };

  const handleObligorSave = (event: { currentTarget: any }) => {
    const form = event.currentTarget.form;
    setEditObligorValidated(false);

    if (form.checkValidity()) {
      let tin = '';
      let orgName = '';
      if (form.obligorTypeControl.value === 'Citizen' || form.obligorTypeControl.value === 'LPR') {
        tin = form.ssnControl.value;
      }
      if (form.obligorTypeControl.value === 'NonCitizen') {
        tin = form.iTINControl.value;
      }
      if (
        form.obligorTypeControl.value === 'LawFirm' ||
        form.obligorTypeControl.value === 'NonProfit'
      ) {
        tin = form.einControl.value;
        orgName = form.obligorOrgNameControl.value;
      }

      const obligorToSave: IObligorInfo = {
        einTinNbr: tin.replaceAll('-', ''),
        typeCode: form.obligorTypeControl.value,
        name: orgName,
        firstName: form.obligorFirstNameControl.value,
        lastName: form.obligorLastNameControl.value,
        phoneNbr: form.phoneControl.value.replaceAll(/[^0-9]/g, ''),
        street1: form.adr1Control.value,
        street2: form.adr2Control.value,
        street3: '',
        city: form.cityControl.value,
        stateCd: obligorStateCd || '',
        zip: form.zipCodeControl.value.replaceAll('-', ''),
      };

      if (obligorToSave) {
        setObligorToSaveCopy(obligorToSave);

        const addressToVerify: ICommonAddress = {
          street1: obligorToSave.street1,
          street2: obligorToSave.street2,
          street3: '',
          city: obligorToSave.city,
          stateCd: obligorToSave.stateCd,
          zip: obligorToSave.zip,
          countryCd: '',
        };
        setAddressToVerify(addressToVerify); // This will trigger the ACS Feedback to verify the address
      }
    } else {
      setEditObligorValidated(true);
    }
  };

  const saveObligor = (obligorToSave: IObligorInfo) => {
    setIsObligorSaving(true);
    ObligorApi.saveObligor(obligorToSave)
      .then((cbo) => {
        // obligor save succeeded
        onSave(cbo);
        setIsObligorSaving(false);
        setShowObligorModal(false);
      })
      .catch((reason) => {
        setIsObligorSaving(false);
        setShowObligorModal(false);
        error.setError('save obligor error ', reason);
      });
  };

  return (
    <>
      <Button variant={getButtonVariant(buttonText)} size='sm' onClick={handleShowObligorModal}>
        {buttonText}
      </Button>
      <Modal show={showObligorModal} onHide={handleObligorModalClose}>
        <Form name='obligorEditForm' noValidate validated={editObligorValidated}>
          <Modal.Header closeButton>
            <Modal.Title>Obligor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-3'>
              You will be the obligor for this bond payment request. Complete the following
              information as it applies to you.
            </div>
            <Row className='mb-2'>
              <Form.Group as={Col} md='6'>
                <div>Your Status</div>
                <Form.Select
                  name='obligorTypeControl'
                  className='form-control'
                  required
                  defaultValue={obligor?.typeCode?.code}
                  aria-label='Select Legal Entity'
                  onChange={onChangeObligorTypeCd}
                >
                  <option value=''>(Please Select)</option>
                  {obligorTypeList?.map((obligorTypeItem) => (
                    <option value={obligorTypeItem.code}>{obligorTypeItem.name}</option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  Please select your status.
                </Form.Control.Feedback>
              </Form.Group>
              {(obligorTypeCd === 'Citizen' || obligorTypeCd === 'LPR') && (
                <Form.Group as={Col} md='6'>
                  <div>
                    <span>Enter your SSN</span>
                  </div>
                  <Form.Control
                    type='text'
                    name='ssnControl'
                    placeholder='SSN'
                    required
                    pattern={ssnRegex}
                    defaultValue={ssnDefault}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter a valid SSN. For example: 123-45-6789
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              {obligorTypeCd === 'NonCitizen' && (
                <Form.Group as={Col} md='6'>
                  <div>
                    <span>Enter ITIN or A-Number</span>
                  </div>
                  <Form.Control
                    type='text'
                    name='iTINControl'
                    placeholder='ITIN or A-Number'
                    required
                    pattern={iTinOrANumberRegex}
                    defaultValue={anumberDefault}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter a valid ITIN or A-Number. For example: 123-45-6789
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              {(obligorTypeCd === 'LawFirm' || obligorTypeCd === 'NonProfit') && (
                <Form.Group as={Col} md='6'>
                  <div>
                    <span>Enter your EIN</span>
                  </div>
                  <Form.Control
                    type='text'
                    name='einControl'
                    placeholder='EIN'
                    required
                    pattern={einRegex}
                    defaultValue={einDefault}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter a valid EIN. For example: 12-3456789
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </Row>
            {(obligorTypeCd === 'LawFirm' || obligorTypeCd === 'NonProfit') && (
              <div>
                <div className='mt-3'>Organization Name</div>
                <Form.Group>
                  <Form.Control
                    type='text'
                    name='obligorOrgNameControl'
                    placeholder='Org. Name'
                    required
                    pattern={nameAddrRegex}
                    maxLength={35}
                    defaultValue={obligor?.name}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please enter valid Org. Name. Special characters are not allowed.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            )}
            <div className='mt-3'>Your Name and Contact Information</div>
            <Row className='mt-2'>
              <Form.Group as={Col} md='6'>
                <Form.Control
                  type='text'
                  name='obligorFirstNameControl'
                  placeholder='First Name'
                  required
                  pattern={nameAddrRegex}
                  maxLength={35}
                  defaultValue={obligor?.firstName}
                />
                <Form.Control.Feedback type='invalid'>
                  Please enter a valid First Name. Special characters are not allowed.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='6'>
                <Form.Control
                  type='text'
                  name='obligorLastNameControl'
                  placeholder='Last Name'
                  required
                  pattern={nameAddrRegex}
                  maxLength={35}
                  defaultValue={obligor?.lastName}
                />
                <Form.Control.Feedback type='invalid'>
                  Please enter a valid Last Name. Special characters are not allowed.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <div className='mt-2 mb-1'>Email: {obligorEmail}</div>
            <Form.Group className='mt-2'>
              <InputGroup>
                <InputGroup.Text id='basic-addon1'>+1</InputGroup.Text>
                <Form.Control
                  type='text'
                  name='phoneControl'
                  placeholder='Phone Number'
                  required
                  pattern={phoneRegex}
                  defaultValue={obligor?.phoneNbr}
                />
                <Form.Control.Feedback type='invalid'>
                  Please enter a valid phone number. For example, 123-456-7890
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <div className='mt-3'>
              {obligorTypeCd === 'LawFirm' || obligorTypeCd === 'NonProfit'
                ? 'Organization'
                : 'Your'}{' '}
              Mailing Address
            </div>
            <Form.Text muted>Bond return checks are mailed to this address</Form.Text>
            <Form.Group className='mb-2'>
              <Form.Control
                type='text'
                id='adr1Control'
                placeholder='Address Line 1'
                required
                pattern={nameAddrRegex}
                maxLength={35}
                defaultValue={obligor?.street1}
              />
              <Form.Control.Feedback type='invalid'>
                Please enter a valid street. Special characters are not allowed.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-2'>
              <Form.Control
                type='text'
                name='adr2Control'
                placeholder='Address Line 2'
                className='mb-2'
                pattern={nameAddrRegex}
                maxLength={35}
                defaultValue={obligor?.street2}
              />
              <Form.Control.Feedback type='invalid'>
                Please enter a valid street. Special characters are not allowed.
              </Form.Control.Feedback>
            </Form.Group>
            <Row className='mb-2'>
              <Form.Group as={Col} md='5' controlId='validationCustom03'>
                <Form.Control
                  type='text'
                  name='cityControl'
                  placeholder='City'
                  required
                  pattern={cityRegex}
                  maxLength={18}
                  defaultValue={obligor?.city}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid city.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md='3' controlId='validationCustom04'>
                <USStateSelect
                  defaultValue={obligorStateCd}
                  required
                  invalidFeedback={'State is required'}
                  onChange={(state) => setObligorStateCd(state.abbreviation)}
                  id={'stateInput'}
                  mini={true}
                />
              </Form.Group>
              <Form.Group as={Col} md='4' controlId='validationCustom05'>
                <Form.Control
                  type='text'
                  name='zipCodeControl'
                  placeholder='Zip'
                  required
                  pattern={zipRegex}
                  defaultValue={obligor?.zip}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid zip.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary' onClick={handleObligorSave} disabled={isObligorSaving}>
              Save
            </Button>
            <Button variant='secondary' onClick={handleObligorModalClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <AcsFeedbackModal
        addressToVerify={addressToVerify}
        onAccept={(acceptedAcsCanidate) => handleAcsFeedbackAccept(acceptedAcsCanidate)}
        onReject={() => handleAcsFeedbackReject()}
      />
      <Modal size='sm' show={isObligorSaving}>
        <Modal.Body>
          <div className='m-3'>
            <Spinner animation='border' role='status' className='me-2' />
            <span>Saving Obligor...</span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ObligorEditModal;
