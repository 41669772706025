import { Table } from 'react-bootstrap';
import { ICeBondsSubject } from '../api/types';

export const SubjectDisplay = ({
  ceBondsSubject,
}: {
  ceBondsSubject: ICeBondsSubject | undefined;
}) => {
  if (!ceBondsSubject) {
    return <div>alien info is empty</div>;
  }
  return (
    <div>
      <div className='fw-bold'>Alien</div>
      <Table size='sm' borderless className='subject-table'>
        <tr>
          <td className='bg-light'>First Name</td>
          <td>{ceBondsSubject?.firstName}</td>
        </tr>
        <tr>
          <td className='bg-light'>Last Name</td>
          <td>{ceBondsSubject?.lastName}</td>
        </tr>
        <tr>
          <td className='bg-light'>A-Number</td>
          <td>{ceBondsSubject?.aNumber}</td>
        </tr>
      </Table>
    </div>
  );
};
