import { useContext } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import DHSIcon from '../../assets/images/dhs-large.png';
import { CeBondsUserContext } from '../../contexts/UserContext';
import { useOktaAuth } from '@okta/okta-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import UserApi from '../../api/UserApi';

const HeaderComponent = () => {
  const ceBondsUser = useContext(CeBondsUserContext);
  const { oktaAuth, authState } = useOktaAuth();

  const handleLogout = () => {
    UserApi.clearSecurityContext();
    oktaAuth.signOut();
  };

  return (
    <Navbar bg='dhs-blue' expand='lg' variant='dark' collapseOnSelect>
      <Navbar.Brand className='me-5'>
        <NavLink to='/'>
          <div className='d-flex'>
            <img src={DHSIcon} alt='Logo' className='d-inline-block my-auto mx-3' height='50px' />
            <div className='text-white d-flex flex-column justify-content-center'>
              <h3 className='m-0'>ICE CeBONDS</h3>
            </div>
          </div>
        </NavLink>
      </Navbar.Brand>
      {authState?.isAuthenticated && <Navbar.Toggle aria-controls='responsive-navbar-nav' />}
      {authState?.isAuthenticated && (
        <Navbar.Collapse>
          <Nav.Item>
            <NavLink to='/' className='mx-3 text-light'>
              <FontAwesomeIcon icon={faHouse} className='me-1' />
              Home
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to='/subject' className='mx-3 text-light'>
              <FontAwesomeIcon icon={faPlus} className='me-1' />
              Bond Payment Request
            </NavLink>
          </Nav.Item>
        </Navbar.Collapse>
      )}
      {authState?.isAuthenticated && (
        <Navbar.Collapse className='justify-content-end text-light'>
          <Nav.Item className='mx-3'>
            <NavDropdown
              title={
                <span>
                  <FontAwesomeIcon icon={faUser} className='me-1' />
                  {ceBondsUser?.email}
                </span>
              }
              id='basic-nav-dropdown'
            >
              <NavDropdown.Item href='account'>Account</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => handleLogout()}>Sign Out</NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
        </Navbar.Collapse>
      )}
    </Navbar>
  );
};

export default HeaderComponent;
