import Api, { NAMESPACE } from './Api';
import {
  IBmisObligor,
  ICommonAddress,
  IObligor,
  IObligorAddress,
  IObligorInfo,
  IObligorType,
} from './types';

class ObligorApi {
  static getObligor(): Promise<IObligor> {
    return Api.GET('/obligor/current');
  }

  static saveObligor(obligorInfo: IObligorInfo): Promise<IObligor> {
    const body = JSON.stringify(obligorInfo);
    return Api.POST('/obligor/save', body).then((response) => response as IObligor);
  }

  static saveAddress(
    bondRequestId: number | undefined,
    newAddress: ICommonAddress,
  ): Promise<Response> {
    const body = JSON.stringify(newAddress);
    const endpoint = '/obligor/saveaddress/' + bondRequestId;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...Api._getAuthHeader() },
      body: body,
    };

    return fetch(NAMESPACE + endpoint, requestOptions).then((response) => {
      return response;
    });
  }

  static getObligorTypes(): Promise<IObligorType[]> {
    return Api.GET('/obligor/typecodes').then((response) => response as IObligorType[]);
  }

  static getBmisObligorForBond(bondRequestId: number): Promise<IBmisObligor> {
    return Api.GET(`/obligor/frombmis/${bondRequestId}`).then((value) => value as IBmisObligor);
  }
}

export default ObligorApi;
