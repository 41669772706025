import Api, { NAMESPACE } from './Api';
import { IPasswordChanges } from './types';

class OktaUserApi {
  static activateUser(oktaUserId: string): Promise<any> {
    const endpoint = '/oktauser/activateuser/' + oktaUserId;

    return Api.GET_unauth(endpoint).then((response) => {
      if (response.status === 200) {
        return undefined;
      } else {
        throw new Error('activateuser unexpected status:' + response.status);
      }
    });
  }

  static verifyEmail(newEmail: string): Promise<any> {
    const endpoint = '/oktauser/verifyuseremail/' + newEmail;

    return Api.GET_raw(endpoint).then((response) => {
      if (response.status === 200) {
        return undefined;
      } else {
        throw new Error('verifyEmail unexpected status:' + response.status);
      }
    });
  }

  static saveEmail(newEmail: string): Promise<any> {
    const endpoint = '/oktauser/updateuseremail/' + newEmail;

    return Api.GET_raw(endpoint).then((response) => {
      if (response.status === 200) {
        return undefined;
      } else {
        throw new Error('saveEmail unexpected status:' + response.status);
      }
    });
  }

  static savePassword(passwordChanges: IPasswordChanges): Promise<Response> {
    const body = JSON.stringify(passwordChanges);
    const endpoint = '/oktauser/updateuserpassword';

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...Api._getAuthHeader() },
      body: body,
    };

    return fetch(NAMESPACE + endpoint, requestOptions).then((response) => {
      return response;
    });
  }
}

export default OktaUserApi;
