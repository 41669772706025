import { BondStatusEnum } from '../models/status';

export const BondStatusContent = ({ bondStatusCode }: { bondStatusCode: string }) => {
  if (bondStatusCode === BondStatusEnum.NEW_BONDABLE_REQUEST) {
    return (
      <span>
        This bond payment request is waiting for ICE review. A review is normally initiated within
        the same business day.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.SUBJECT_NOT_BONDABLE) {
    return (
      <span>
        There are no matching or no releasable aliens for this bond payment request. If you need
        further assistance with your bond payment request, please contact your&nbsp;
        <a
          href='http://www.ice.gov/contact/ero/'
          target='_blank'
          rel='noreferrer'
          className='fw-bold'
        >
          local field office.
        </a>
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.NEW_BOND_REQUEST_RECEIVED_AFTER_HRS) {
    return (
      <span>
        This bond payment request was received after business hours. ICE will conduct a review for
        this request when business hours resume. Bond posting hours are Monday through Friday from 9
        a.m. – 3 p.m. (excluding government holidays). Bond posting hours are applied in the time
        zone where the alien is detained.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.NEW_BOND_REQUEST_VIEWED_BY_OFFICER) {
    return (
      <span>
        This bond payment request is currently under ICE review. We will send you an email
        notification when this review is complete.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.APPROVED_BONDABLE_REQUEST) {
    return (
      <span>
        ICE has completed its review of your bond payment request and determined that a bond has
        been set for the alien and has approved you as the obligor.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.DENIED_BONDABLE_REQUEST) {
    return (
      <span>
        ICE has completed the review for this bond payment request and it has been denied. See
        denial reason for more detail.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.I352_SIGNED_BY_OBLIGOR) {
    return (
      <span>
        Now that you have signed your I-352 bond contract, you must proceed with your bond payment.
        Please click the payment button below to get started with your bond payment. Be sure to
        complete both step 1 and step 2 of the bond payment.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.BOND_PAYMENT_UPLOADED) {
    return (
      <span>
        You have successfully signed your I-352 contract and have uploaded your bond payment
        receipt. Your contract and payment receipt may now be submitted to ICE for review.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.I352_CREATED_IN_EARM) {
    return (
      <span>
        Your I-352 contract and payment have been submitted to ICE. These items will be reviewed by
        ICE.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.I352_VIEWED_BY_OFFICER) {
    return (
      <span>
        Your I-352 contract and payment are currently under ICE review. We will send you an email
        notification when this review is complete.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.APPROVED_I352_REQUEST) {
    return (
      <span>
        Your I-352 contract has been approved. The release process for the associated alien will
        proceed.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.DENIED_I352_REQUEST) {
    return (
      <span>
        Your I-352 contract has been denied. See comments from ICE for more detail. If you are given
        instructions regarding this denial, then click the Sign Contract button below to make any
        requested corrections and sign a new I-352 contract.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.INVALID_I352) {
    return (
      <span>
        We were not able to accept your I-352 contract at this time. The bond related to your
        payment request may have been deleted or the status may have changed. Consider withdrawing
        this bond payment request and starting a new request.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.BOND_WITHDRAWN) {
    return (
      <span>This bond payment request has been withdrawn. No further action will be taken.</span>
    );
  }
  if (bondStatusCode === BondStatusEnum.SUBJECT_RELEASED) {
    return <span>The alien has been released from ICE custody.</span>;
  }
  if (bondStatusCode === BondStatusEnum.REFUND_ADDR_CORRECT) {
    return (
      <span>
        You confirmed that your bond refund mailing address is correct or updated your address. If a
        bond breach notice was given, then an interest only refund check will be mailed to you. If a
        bond cancelled notice was given, then a bond amount plus interest refund check will be
        mailed to you.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.I340_VIEWED) {
    return (
      <span>
        You have viewed the OBLIGOR TO DELIVER ALIEN (I-340) notice given to you. Please ensure that
        you comply with this notice. If needed, you may download and review the notice again using
        the download link above.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.I323_VIEWED) {
    return (
      <span>
        You have viewed the IMMIGRATION BOND BREACHED (I-323) notice given to you. Follow any
        instructions provided in the notice. Please review your (obligor) mailing address. It is
        important that your mailing address is correct. Your bond <em>interest only</em> refund
        check will be mailed to the address on file. Not verifying your address could delay your
        check. If needed, you may download and review the notice again using the download link
        above.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.I391_VIEWED) {
    return (
      <span>
        You have viewed the IMMIGRATION BOND CANCELLED (I-391) notice given to you. Follow any
        instructions provided in the notice. Please review your (obligor) mailing address. It is
        important that your mailing address is correct. Your bond amount <em>plus interest</em>{' '}
        refund check will be mailed to the address on file. Not verifying your address could delay
        your check. You may download and review the notice again using the download link above.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.MTR_VIEWED) {
    return (
      <span>
        You have viewed the BOND BREACH RECONSIDERATION DECISION (MTR) notice given to you. Follow
        any instructions provided in this notice. If neeeded, you may download and review the notice
        again using the download link above.
      </span>
    );
  }
  if (bondStatusCode === BondStatusEnum.MTG_VIEWED) {
    return (
      <span>
        You have viewed the BOND MITIGATION (MTG) notice given to you. Follow any instructions
        provided in the notice. If needed, you may download and review the notice again using the
        download link above.
      </span>
    );
  }

  return <span>Content not found for: {bondStatusCode}</span>;
};
