import { Spinner } from 'react-bootstrap';

export interface ILoadingPageProps {
  message?: string;
}

const LoadingPage = ({ message }: ILoadingPageProps) => {
  //todo: place holder, make this better later
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '2rem' }}>
      <Spinner animation='border' role='status' />
      {message && <p className={'m-4'}>{message}</p>}
    </div>
  );
};

export default LoadingPage;
