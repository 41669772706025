import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

// Define props type
interface PasswordInputProps {
  onPasswordChange: (password: string) => void;
  enforcePolicy?: boolean;
}

const PasswordInput: React.FC<PasswordInputProps> = ({ onPasswordChange, enforcePolicy }) => {
  const [password, setPassword] = useState<string>('');
  const [passwordPattern, setPasswordPattern] = useState<string>();
  const [isShowingPassword, setIsShowingPassword] = useState<boolean>(false);

  useEffect(() => {
    if (enforcePolicy) {
      setPasswordPattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&#^_+=\\-]).{12,}$');
    } else {
      setPasswordPattern(undefined);
    }
  }, []);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    onPasswordChange(newPassword); // Notify parent component
  };

  const togglePasswordVisibility = () => {
    setIsShowingPassword(!isShowingPassword);
  };

  return (
    <div>
      <InputGroup>
        <Form.Control
          className='password-input'
          type={isShowingPassword ? 'text' : 'password'}
          value={password}
          onChange={handlePasswordChange}
          required
          autoComplete='off'
          pattern={passwordPattern}
          maxLength={72}
        />
        <span
          className='password-toggle'
          onClick={togglePasswordVisibility}
          title='show/hide password'
        >
          <FontAwesomeIcon className='text-muted' icon={isShowingPassword ? faEyeSlash : faEye} />
        </span>
        <Form.Control.Feedback type='invalid'>
          Please enter a valid password. The password must meet the requirements above.
        </Form.Control.Feedback>
      </InputGroup>
    </div>
  );
};

export default PasswordInput;
