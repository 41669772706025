import { useEffect, useState } from 'react';
import { Alert, Button, Container, Spinner } from 'react-bootstrap';
import OktaUserApi from '../api/OktaUserApi';
import { useOktaAuth } from '@okta/okta-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

const VerifyEmailPage = () => {
  // This page is reached via a link in the verify email message. The user clicks the link
  // in the email message and this page is loaded. That means the email address was
  // verified and we can save the email now.
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [newEmail, setNewEmail] = useState<string>();
  const { oktaAuth } = useOktaAuth();

  document.title = 'Verify Email - ICE CeBONDS';
  console.log('VerifyEmailPage');

  useEffect(() => {
    document.title = 'Verify Email - ICE CeBONDS';
    console.log('VerifyEmailPage');

    if (!newEmail) {
      console.log('VerifyEmailPage getting newemail');
      const params = new URLSearchParams(window.location.search);
      const newEmailParam = params.get('newemail');

      if (newEmailParam) {
        setNewEmail(newEmailParam);
        console.log('VerifyEmailPage got newemail');
      } else {
        console.error('VerifyEmailPage newemail querystring parameter is missing.');
      }
    }
  }, [newEmail]);

  const handleVerifyEmail = () => {
    if (newEmail) {
      console.log('VerifyEmailPage got newemail');
      setIsProcessing(true);
      OktaUserApi.saveEmail(newEmail)
        .then(() => {
          console.log('VerifyEmailPage saved newemail');
          oktaAuth.signInWithRedirect(); // Refreshes the Okta token.
        })
        .catch((err) => {
          console.error('OktaUserApi.saveEmail failed', err);
          setIsProcessing(false);
          setIsError(true);
        });
    } else {
      console.error('newemail querystring parameter is missing.');
    }
  };

  return (
    <Container>
      <h3 className='mt-3 mb-3'>CeBONDS Email Verification</h3>
      {newEmail && (
        <div>
          <div className='mb-3'>
            Ready to verify your new email address. Click the Verify/Update Email button below to
            verify and update your username/email.
            <br />
            New Email Address: <span className='fw-bold'>{newEmail}</span>
          </div>
          <div>
            <Button variant='primary' onClick={handleVerifyEmail}>
              Verify/Update Email
            </Button>
          </div>
        </div>
      )}
      {isProcessing && (
        <div>
          <div className='mb-3 mt-3'>
            <Spinner size='sm' animation='border' role='status' className='me-2' />
            Please wait...
          </div>
          <Alert variant='info'>
            <FontAwesomeIcon className='me-2 fs-4' icon={faInfoCircle} color={'mediumblue'} />
            Your email verification for {newEmail} is being processed.
          </Alert>
        </div>
      )}
      {isError && (
        <Alert className='mt-3' variant='danger'>
          <FontAwesomeIcon className='me-2 fs-4' icon={faXmarkCircle} color={'red'} />
          We are not able to complete your change to use {newEmail}. Check to make sure you are not
          already using this email for another CeBONDS account and that it is a valid email address.
        </Alert>
      )}
    </Container>
  );
};

export default VerifyEmailPage;
