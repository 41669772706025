import { Link } from 'react-router-dom';

export interface INotFoundProps {
  message?: string;
}

const NotFound = ({ message }: INotFoundProps) => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1 style={{ fontSize: '3rem', color: '#ff6b6b' }}>404</h1>
      <p style={{ fontSize: '1.5rem', color: '#333' }}>
        Oops! The page you are looking for does not exist.
      </p>
      <p>{message}</p>
      <Link to='/' style={{ fontSize: '1.2rem', color: '#4caf50', textDecoration: 'underline' }}>
        Go back to the homepage
      </Link>
    </div>
  );
};

export default NotFound;
